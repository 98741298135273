import { lazy } from 'react';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';

import './helpers/functions/Interceptors';

const Index = Loadable(lazy(() => import('app/views/base/baseCrud/Index')));
const Form = Loadable(lazy(() => import('app/views/base/baseCrud/Form')));
const Detail = Loadable(lazy(() => import('app/views/base/baseCrud/Detail')));

const Artikel = Loadable(lazy(() => import('app/views/website/artikel/Index')));
const ArtikelForm = Loadable(lazy(() => import('app/views/website/artikel/Form')));
const ArtikelDetail = Loadable(lazy(() => import('app/views/website/artikel/Detail')));

const Faq = Loadable(lazy(() => import('app/views/website/faq/Index')));
const FaqForm = Loadable(lazy(() => import('app/views/website/faq/Form')));
const FaqDetail = Loadable(lazy(() => import('app/views/website/faq/Detail')));

const Karir = Loadable(lazy(() => import('app/views/website/karir/Index')));
const KarirForm = Loadable(lazy(() => import('app/views/website/karir/Form')));
const KarirDetail = Loadable(lazy(() => import('app/views/website/karir/Detail')));

const IndexWebsiteSliderEvent = Loadable(lazy(() => import('app/views/website/sliderEvent/Index')));
const FormWebsiteSliderEvent = Loadable(lazy(() => import('app/views/website/sliderEvent/Form')));
const DetailWebsiteSliderEvent = Loadable(
  lazy(() => import('app/views/website/sliderEvent/Detail'))
);

const IndexWebsiteTentangMyPro = Loadable(
  lazy(() => import('app/views/website/tentangMyPro/Index'))
);
const FormWebsiteTentangMyPro = Loadable(lazy(() => import('app/views/website/tentangMyPro/Form')));
const DetailWebsiteTentangMyPro = Loadable(
  lazy(() => import('app/views/website/tentangMyPro/Detail'))
);


// const IndexAuthManagementAkun = Loadable(lazy(() => import('app/views/auth/managementAkun/Index')));
const IndexAgentManagementAkun = Loadable(lazy(() => import('app/views/auth/agents/index')));
const FormAgentManagement = Loadable(lazy(() => import('app/views/auth/agents/form')))
const DetailAgentManagement = Loadable(lazy(() => import('app/views/auth/agents/form')))
// const FormAuthManagementAkun = Loadable(lazy(() => import('app/views/auth/managementAkun/Form')));
// const DetailAuthManagementAkun = Loadable(lazy(() => import('app/views/auth/managementAkun/Detail')));

const IndexAuthPengguna = Loadable(lazy(() => import('app/views/auth/pengguna/Index')));
const FormAuthPengguna = Loadable(lazy(() => import('app/views/auth/pengguna/Form')));
const DetailAuthPengguna = Loadable(lazy(() => import('app/views/auth/pengguna/Detail')));


const IndexAuthCabang = Loadable(lazy(() => import('app/views/auth/cabang/Index')));
const FormAuthCabang = Loadable(lazy(() => import('app/views/auth/cabang/Form')));
const DetailAuthCabang = Loadable(lazy(() => import('app/views/auth/cabang/Detail')));


const IndexAuthLog = Loadable(lazy(() => import('app/views/auth/log/Index')));
const FormAuthLog = Loadable(lazy(() => import('app/views/auth/log/Form')));
const DetailAuthLog = Loadable(lazy(() => import('app/views/auth/log/Detail')));


// const IndexTransactionPAP = Loadable(lazy(() => import('app/views/transaction/pAP/Index')));
// const FormTransactionPAP = Loadable(lazy(() => import('app/views/transaction/pAP/Form')));
// const DetailTransactionPAP = Loadable(lazy(() => import('app/views/transaction/pAP/Detail')));

const PropertiesPapManagements = Loadable(lazy(() => import('app/views/transaction/pap/index')))
const PropertiesFormPapManagements = Loadable(lazy(() => import('app/views/transaction/pap/form')))

const IndexTransactionRewards = Loadable(lazy(() => import('app/views/transaction/rewards/index')))
const FormTransactionRewards = Loadable(lazy(() => import('app/views/transaction/rewards/form')))
const DetailTransactionRewards = Loadable(lazy(() => import('app/views/transaction/rewards/detail')))


const IndexTransactionKontrak = Loadable(lazy(() => import('app/views/transaction/kontrak/Index')));
const FormTransactionKontrak = Loadable(lazy(() => import('app/views/transaction/kontrak/Form')));
const DetailTransactionKontrak = Loadable(lazy(() => import('app/views/transaction/kontrak/Detail')));


const IndexTransactionKwitansi = Loadable(lazy(() => import('app/views/transaction/kwitansi/Index')));
const FormTransactionKwitansi = Loadable(lazy(() => import('app/views/transaction/kwitansi/Form')));
const DetailTransactionKwitansi = Loadable(lazy(() => import('app/views/transaction/kwitansi/Detail')));

const FinanceAccountManagements = Loadable(lazy(() => import('app/views/finances/accounts')))
const FinanceFormAccountManagements = Loadable(lazy(() => import('app/views/finances/accounts/form')))
const FinanceDetailAccountManagements = Loadable(lazy(() => import('app/views/finances/accounts/detail')))

const FinanceInventoryManagements = Loadable(lazy(() => import('app/views/finances/inventories')))
const FinanceFormInventoryManagements = Loadable(lazy(() => import('app/views/finances/inventories/form')))

const FinanceCompanyManagements = Loadable(lazy(() => import('app/views/finances/companies')))
const FinanceFormCompanyManagements = Loadable(lazy(() => import('app/views/finances/companies/form')))

const FinanceVoucherManagements = Loadable(lazy(() => import('app/views/finances/vouchers')))
const FinanceFormVoucherManagements = Loadable(lazy(() => import('app/views/finances/vouchers/form')))

const FinanceJurnalManagements = Loadable(lazy(() => import('app/views/finances/jurnals')))
const FinanceFormJurnalManagements = Loadable(lazy(() => import('app/views/finances/jurnals/form')))

const FinanceReportManagements = Loadable(lazy(() => import('app/views/finances/reports')))
const MasterDocumentManagements = Loadable(lazy(() => import('app/views/masters/documents/index')))

const DocumentKomisi = Loadable(lazy(() => import('app/views/documents/komisi')))
const DocumentPenjualan = Loadable(lazy(() => import('app/views/documents/penjualan')))

//[1]

const routesChildren = [
  { path: 'base/base-crud', element: <Index />, auth: authRoles.admin },
  { path: 'base/base-crud/form', element: <Form />, auth: authRoles.admin },
  { path: 'base/base-crud/form/:id', element: <Form />, auth: authRoles.admin },
  { path: 'base/base-crud/detail/:id', element: <Detail />, auth: authRoles.admin },

  { path: 'website/artikel', element: <Artikel />, auth: authRoles.admin },
  { path: 'website/artikel/create', element: <ArtikelForm />, auth: authRoles.admin },
  { path: 'website/artikel/update/:id', element: <ArtikelForm />, auth: authRoles.admin },
  { path: 'website/artikel/detail/:id', element: <ArtikelForm />, auth: authRoles.admin },

  { path: 'website/faq', element: <Faq />, auth: authRoles.admin },
  { path: 'website/faq/form', element: <FaqForm />, auth: authRoles.admin },
  { path: 'website/faq/form/:id', element: <FaqForm />, auth: authRoles.admin },
  { path: 'website/faq/detail/:id', element: <FaqDetail />, auth: authRoles.admin },

  { path: 'website/karir', element: <Karir />, auth: authRoles.admin },
  { path: 'website/karir/form', element: <KarirForm />, auth: authRoles.admin },
  { path: 'website/karir/form/:id', element: <KarirForm />, auth: authRoles.admin },
  { path: 'website/karir/detail/:id', element: <KarirDetail />, auth: authRoles.admin },

  { path: 'website/slider-event', element: <IndexWebsiteSliderEvent />, auth: authRoles.admin },
  { path: 'website/slider-event/create', element: <FormWebsiteSliderEvent />, auth: authRoles.admin },
  { path: 'website/slider-event/update/:id', element: <FormWebsiteSliderEvent />, auth: authRoles.admin },
  { path: 'website/slider-event/detail/:id', element: <FormWebsiteSliderEvent />, auth: authRoles.admin },

  { path: 'website/tentang-mypro', element: <IndexWebsiteTentangMyPro />, auth: authRoles.admin },
  { path: 'website/tentang-mypro/form', element: <FormWebsiteTentangMyPro />, auth: authRoles.admin },
  { path: 'website/tentang-mypro/form/:id', element: <FormWebsiteTentangMyPro />, auth: authRoles.admin },
  { path: 'website/tentang-mypro/detail/:id', element: <DetailWebsiteTentangMyPro />, auth: authRoles.admin },

  { path: 'auth/agen', element: <IndexAgentManagementAkun />, auth: authRoles.admin },
  { path: 'auth/agen/create', element: <FormAgentManagement />, auth: authRoles.admin },
  { path: 'auth/agen/update/:id', element: <FormAgentManagement />, auth: authRoles.admin },
  { path: 'auth/agen/detail/:id', element: <FormAgentManagement />, auth: authRoles.admin },

  { path: 'auth/pengguna', element: <IndexAuthPengguna />, auth: authRoles.admin },
  { path: 'auth/pengguna/create', element: <FormAuthPengguna />, auth: authRoles.admin },
  { path: 'auth/pengguna/:id/update', element: <FormAuthPengguna />, auth: authRoles.admin },
  { path: 'auth/pengguna/:id', element: <FormAuthPengguna />, auth: authRoles.admin },

  { path: 'auth/cabang', element: <IndexAuthCabang />, auth: authRoles.admin },
  { path: 'auth/cabang/create', element: <FormAuthCabang />, auth: authRoles.admin },
  { path: 'auth/cabang/update/:id', element: <FormAuthCabang />, auth: authRoles.admin },
  { path: 'auth/cabang/detail/:id', element: <FormAuthCabang />, auth: authRoles.admin },

  { path: 'auth/auth-log', element: <IndexAuthLog />, auth: authRoles.admin },
  { path: 'auth/auth-log/form', element: <FormAuthLog />, auth: authRoles.admin },
  { path: 'auth/auth-log/form/:id', element: <FormAuthLog />, auth: authRoles.admin },
  { path: 'auth/auth-log/detail/:id', element: <DetailAuthLog />, auth: authRoles.admin },

  { path: 'properties/pap', element: <PropertiesPapManagements />, auth: authRoles.admin },
  { path: 'properties/pap/create', element: <PropertiesFormPapManagements />, auth: authRoles.admin },
  { path: 'properties/pap/:id', element: <PropertiesFormPapManagements />, auth: authRoles.admin },
  { path: 'properties/pap/:id/update', element: <PropertiesFormPapManagements />, auth: authRoles.admin },
  // { path: 'properties/pap/detail/:id', element: <DetailTransactionPAP />, auth: authRoles.admin },

  { path: 'properties/rewards', element: <IndexTransactionRewards />, auth: authRoles.admin },
  { path: 'properties/rewards/create', element: <FormTransactionRewards />, auth: authRoles.admin },
  { path: 'properties/rewards/:id', element: <FormTransactionRewards />, auth: authRoles.admin },
  { path: 'properties/rewards/update/:id', element: <FormTransactionRewards />, auth: authRoles.admin },

  { path: 'properties/contracts', element: <IndexTransactionKontrak />, auth: authRoles.admin },
  { path: 'properties/contracts/create', element: <FormTransactionKontrak />, auth: authRoles.admin },
  { path: 'properties/contracts/update/:id', element: <FormTransactionKontrak />, auth: authRoles.admin },
  { path: 'properties/contracts/detail/:id', element: <FormTransactionKontrak />, auth: authRoles.admin },

  { path: 'properties/receipts', element: <IndexTransactionKwitansi />, auth: authRoles.admin },
  { path: 'properties/receipts/create', element: <FormTransactionKwitansi />, auth: authRoles.admin },
  { path: 'properties/receipts/update/:id', element: <FormTransactionKwitansi />, auth: authRoles.admin },
  { path: 'properties/receipts/detail/:id', element: <FormTransactionKwitansi />, auth: authRoles.admin },

  { path: 'master/documents', element: <MasterDocumentManagements />, auth: authRoles.admin },



  { path: 'finances/accounts', element: <FinanceAccountManagements />, auth: authRoles.finance },
  { path: 'finances/accounts/create', element: <FinanceFormAccountManagements />, auth: authRoles.finance },
  { path: 'finances/accounts/update/:id', element: <FinanceFormAccountManagements />, auth: authRoles.finance },
  { path: 'finances/accounts/detail/:id', element: <FinanceDetailAccountManagements />, auth: authRoles.finance },

  { path: 'finances/companies', element: <FinanceCompanyManagements />, auth: authRoles.finance },
  { path: 'finances/companies/create', element: <FinanceFormCompanyManagements />, auth: authRoles.finance },
  { path: 'finances/companies/update/:id', element: <FinanceFormCompanyManagements />, auth: authRoles.finance },
  { path: 'finances/companies/:id', element: <FinanceFormCompanyManagements />, auth: authRoles.finance },

  { path: 'finances/inventories', element: <FinanceInventoryManagements />, auth: authRoles.finance },
  { path: 'finances/inventories/create', element: <FinanceFormInventoryManagements />, auth: authRoles.finance },
  { path: 'finances/inventories/update/:id', element: <FinanceFormInventoryManagements />, auth: authRoles.finance },
  { path: 'finances/inventories/:id', element: <FinanceFormInventoryManagements />, auth: authRoles.finance },

  { path: 'finances/vouchers', element: <FinanceVoucherManagements />, auth: authRoles.finance },
  { path: 'finances/vouchers/create', element: <FinanceFormVoucherManagements />, auth: authRoles.finance },
  { path: 'finances/vouchers/update/:id', element: <FinanceFormVoucherManagements />, auth: authRoles.finance },
  { path: 'finances/vouchers/:id', element: <FinanceFormVoucherManagements />, auth: authRoles.finance },

  { path: 'finances/jurnals', element: <FinanceJurnalManagements />, auth: authRoles.finance },
  { path: 'finances/jurnals/create', element: <FinanceFormJurnalManagements />, auth: authRoles.finance },
  { path: 'finances/jurnals/update/:id', element: <FinanceFormJurnalManagements />, auth: authRoles.finance },
  { path: 'finances/jurnals/:id', element: <FinanceFormJurnalManagements />, auth: authRoles.finance },
  
  { path: 'finances/reports', element: <FinanceReportManagements />, auth: authRoles.finance },

  { path: 'documents/report-sells', element: <DocumentPenjualan />, auth: authRoles.all },
  { path: 'documents/report-commisions', element: <DocumentKomisi />, auth: authRoles.all },
  
  
  //[2]
];
export default routesChildren;
