import { CssBaseline } from '@mui/material';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import { getToken } from './helpers/functions/LocalStorageServices';
import { useEffect } from 'react';
import useAuths from './hooks/auth/useAuth';
import './app.css'
// import { navigations } from './navigations';
// import useAuth from './hooks/useAuth';

const App = () => {
  const content = useRoutes(routes);

  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, setAuthStatus } = useAuths();
  // const { user } = useAuth()

  useEffect(() => {
    if(location.pathname.includes('forgot-password')) return;
    if(location.pathname.includes('verify')) return;
    if(location.pathname.includes('reset-password')) return;
    const token = getToken();
    if (token) {
      if (!isAuthenticated) {
        setAuthStatus(true);
      }
    } else {
      setAuthStatus(false);
      navigate('/login');
    }
  }, [isAuthenticated, setAuthStatus, navigate]);

  // validate navifation
  // useEffect(() => {
  //   const autorized = navigations.find(nav => location.pathname.includes(nav.path) && nav.roles.includes(user?.role));
  //   console.log(autorized)
  // }, [location, user])

  return (
    <>
      <SettingsProvider>
        <AuthProvider>
          <MatxTheme>
            <CssBaseline />
            {content}
            <ToastContainer />
          </MatxTheme>
        </AuthProvider>
      </SettingsProvider>
    </>
  );
};

export default App;
