export const authRoles = {
  sa: ['SUPER ADMIN'], // Only Super Admin has access
  admin: ['SUPER ADMIN', 'ADMIN'], // Only SA & Admin has access
  editor: ['SUPER ADMIN', 'ADMIN', 'EDITOR'], // Only SA & Admin & Editor has access
  guest: ['SUPER ADMIN', 'ADMIN', 'EDITOR', 'GUEST'], // Everyone has access
  finance: ['SUPER ADMIN', 'FINANCE', 'ACCOUNTING'],
  accounting: ['SUPER ADMIN','ACCOUNTING'],
  financeAndAccounting: ['SUPER ADMIN', 'ACCOUNTING', 'FINANCE'],
  all: ['SUPER ADMIN', 'FINANCE', 'ACCOUNTING', 'PRINCIPLE', 'ADMIN', 'MANAGEMENT'],
  pap: ['SUPER ADMIN', 'FINANCE', 'ACCOUNTING', 'PRINCIPLE', 'ADMIN', 'MANAGEMENT', 'WEB & MEDIA'],
  roleWithoutWebAdmin: ['SUPER ADMIN', 'FINANCE', 'ACCOUNTING', 'PRINCIPLE', 'ADMIN'],
  withoutCabang: ['SUPER ADMIN', 'FINANCE', 'ACCOUNTING'],
  cabang: ['SUPER ADMIN', 'PRINCIPLE', 'ADMIN', 'WEB & MEDIA', 'MANAGEMENT'],
  onlyCabang: ['SUPER ADMIN', 'PRINCIPLE', 'ADMIN', 'MANAGEMENT'],
  content: ['SUPER ADMIN', 'WEB & MEDIA'],
  adminwebagen: ['SUPER ADMIN', 'PRINCIPLE', 'ADMIN', 'MANAGEMENT', 'WEB & MEDIA'],
  adminagen: ['SUPER ADMIN', 'PRINCIPLE', 'ADMIN', 'MANAGEMENT'],
};
