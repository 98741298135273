import { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import './helpers/functions/Interceptors';
import routesChildren from './pages';
import useAuths from './hooks/auth/useAuth';

// auth pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const Login = Loadable(lazy(() => import('app/views/auth/Login')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));
const VerifyCode = Loadable(lazy(() => import('app/views/sessions/VerifyCode')));
const ResetPassword = Loadable(lazy(() => import('app/views/sessions/ResetPassword')));

// pages
const Dashboard = Loadable(lazy(() => import('app/views/dashboard/index')));
// const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));
// const BaseCrud = Loadable(lazy(() => import('app/views/base-crud/AppBaseCrud')));
// const AddEditBaseCrud = Loadable(lazy(() => import('app/views/base-crud/components/AddEditData')));
// const DetailBaseCrud = Loadable(lazy(() => import('app/views/base-crud/components/Detail')));

// Handle dynamic redirection
const RedirectBasedOnAuth = () => {
  const { isAuthenticated } = useAuths();
  const location = useLocation();
  if(location.pathname.includes('forgot-password')) return;
  // If authenticated, redirect to the dashboard, otherwise, redirect to login
  return isAuthenticated ? <Navigate to="/dashboard/default" /> : <Navigate to="/login" />;
};

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      ...routesChildren,
      // Redirect based on authentication status
      {
        path: '/',
        element: <RedirectBasedOnAuth />
      },

      // dashboard route
      {
        path: '/dashboard/default',
        element: <Dashboard />,
        auth: authRoles.admin
      }

      // {
      //   path: '/base-crud',
      //   element: <BaseCrud />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/base-crud/detail/:id',
      //   element: <DetailBaseCrud />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/base-crud/form',
      //   element: <AddEditBaseCrud />,
      //   auth: authRoles.admin
      // },
      // {
      //   path: '/base-crud/form/:id',
      //   element: <AddEditBaseCrud />,
      //   auth: authRoles.admin
      // },

      // // e-chart rooute
      // {
      //   path: '/charts/echarts',
      //   element: <AppEchart />,
      //   auth: authRoles.editor
      // }
    ]
  },

  // auth pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <JwtRegister /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/verify', element: <VerifyCode /> },
  { path: '/reset-password', element: <ResetPassword /> },

  {
    path: '/',
    element: <RedirectBasedOnAuth />
  },

  { path: '*', element: <NotFound /> }
];
export default routes;
